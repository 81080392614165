
.event {
  display: flex;
  color: white;
  margin: 5px 0 0 0;
  padding: 5px 0 8px 0;
}

.event-break {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  color: white;
  margin: 5px 0 0 0;
  padding: 0 0 10px 0;
}

.event-title {
  color: #494c4e;
  font-family: Muli;
  font-size: 12px;
  font-weight: 700;
  text-align: left;
  margin: 0;
}

.event-title-light {
  color: #494c4e;
  font-family: Muli;
  font-size: 12px;
  font-weight: 400;
  text-align: left;
  margin: 0;
}

.event-time {
  color: #494c4e;
  font-family: Muli;
  font-size: 12px;
  font-weight: 700;
  text-align: left;
  margin: 0;
}

.event-time-light {
  color: #494c4e;
  font-family: Muli;
  font-size: 12px;
  font-weight: 400;
  text-align: left;
  margin: 0;
}

.event-description {
  color: #494c4e;
  font-family: Muli;
  font-size: 10px;
  font-weight: 400;
  text-align: left;
  margin: 0px;
}

.event-row-right {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.event-row-left {
  min-width: 160px;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
